
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  data() {
    return {
      fruits: [  
        { name: 'Manzana', months: [9, 10, 11] },
        { name: 'Plátano', months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] },
        { name: 'Pomelo', months: [1, 2, 3] },
        { name: 'Fresa', months: [4, 5, 6] },
        { name: 'Albaricoque', months: [5, 6, 7] },
        { name: 'Ciruela', months: [5, 6, 7, 8] },
        { name: 'Nectarina', months: [5, 6, 7, 8] },
        { name: 'Melón', months: [5, 6, 7, 8, 9] },
        { name: 'Arándano', months: [6, 7, 8] },
        { name: 'Frambuesa', months: [6, 7, 8, 9] },
        { name: 'Melocotón', months: [6, 7, 8, 9, 10] },
        { name: 'Sandía', months: [7, 8] },
        { name: 'Pera', months: [1, 2, 3, 7, 8, 9, 10, 11, 12] },
        { name: 'Cereza', months: [5, 6] },
        { name: 'Uva', months: [8, 9, 10] },
        { name: 'Higo', months: [8, 9, 10] },
        { name: 'Limón', months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] },
        { name: 'Naranja', months: [1, 2, 3, 4, 11, 12] },
        { name: 'Mandarina', months: [1, 2, 10, 11, 12] }
      ],
      vegetables: [
        { name: 'Cebolla', months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] },
        { name: 'Coliflor', months: [1, 2, 3, 4] },
        { name: 'Grelo', months: [1, 2, 3] },
        { name: 'Haba tierna', months: [1, 2, 3, 4, 5] },
        { name: 'Achicoria', months: [1, 2, 3, 4, 5] },
        { name: 'Berro', months: [1, 2, 3, 4, 5, 6] },
        { name: 'Espárrago', months: [2, 3, 4, 5, 6] },
        { name: 'Puerro', months: [1, 2, 3, 4, 5, 8, 9, 10, 11, 12] },
        { name: 'Chirivía', months: [1, 2, 9, 10, 11] },
        { name: 'Acelga', months: [1, 2, 3, 9, 10, 11, 12] },
        { name: 'Apio', months: [1, 2, 10, 11] },
        { name: 'Calabaza', months: [1, 2, 10, 11, 12] },
        { name: 'Canónigo', months: [1, 2, 3, 10, 11, 12] },
        { name: 'Alcachofa', months: [1, 2, 3, 4, 10, 11, 12] },
        { name: 'Remolacha', months: [1, 2, 3, 4, 5, 6, 10, 11, 12] },
        { name: 'Brócoli', months: [1, 2, 3, 4, 11, 12] },
        { name: 'Espinaca', months: [1, 2, 3, 12] }
      ],
      monthNames: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
      ]
    };
  },
  computed: {
    filteredFruits(): Array<{ name: string, months: Array<number> }> {
      let currentMonth = new Date().getMonth() + 1;
      return this.fruits.filter(fruit => fruit.months.includes(currentMonth));
    },
    filteredVegetables(): Array<{ name: string, months: Array<number> }> {
      let currentMonth = new Date().getMonth() + 1;
      return this.vegetables.filter(veg => veg.months.includes(currentMonth));
    },
    currentMonthName(): string {
      let currentMonth = new Date().getMonth();
      return this.monthNames[currentMonth];
    },
    openTab(name: string): any {
      window.open(`https://www.google.com/search?q=${name}`, '_blank');
      return false;
    }
  },
});
